<template>
	<div>
		<ContentHeader3 title="Membership" url="/membership" title2="Data Membership" url2="/data-administrasi" subTitle="Tambah Data Membership" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
		            <div class="col">
		            	<div class="card">
		            		<div class="card-header row">
		            			<h3 class="card-title">Input Data Membership</h3>
		            		</div>
		            		<div class="card-body row">
		            			<div class="col-2">

								</div>
		            			<div class="col-8">
									<form @submit.prevent="prosesData">
										<div class="px-lg-2 ">
										<div class="form-group">
											<label for="nama">Nama <span class="text-danger">*</span></label>
											<input type="text" class="form-control" id="nama" name="nama" v-model="membership.nama" required="" placeholder="Masukkan Nama">
										</div>
										<div class="form-group">
											<label for="email">Email <span class="text-danger">*</span></label>
											<input class="form-control" type="email" name="email" required="" v-model="membership.email" placeholder="Masukkan Email" />
										</div>
										<div class="form-group">
											<label for="email">Tanggal Lahir <span class="text-danger">*</span></label>
											<input class="form-control" type="date" name="tanggal_lahir" required="" v-model="membership.tanggal_lahir" placeholder="Masukkan Tanggal Lahir" />
										</div>
										<div class="form-group">
											<label for="angkatan">Angkatan <span class="text-danger">*</span></label>
											<input class="form-control" type="number" name="angkatan" min="1900" max="2022" step="1" required="" v-model="membership.angkatan" placeholder="Masukkan Angkatan" />
										</div>
										<div class="form-group">
											<label for="npm">NPM <span class="text-danger">*</span></label>
											<input class="form-control" type="number" name="npm" required="" v-model="membership.npm" placeholder="Masukkan NPM" />
										</div>
										<div class="form-group">
											<label for="no_hp">Nomor Handphone <span class="text-danger">*</span></label>
											<input class="form-control" type="number" name="no_hp" required="" v-model="membership.no_hp" placeholder="Masukkan Nomor Handphone" />
										</div>
										<div class="form-group">
											<label for="">Provinsi <span class="text-danger">*</span></label>
											<select class="custom-select form-control" @change="getKota(membership.provinsi)" v-model="membership.provinsi">
												<option selected>Pilih Provinsi</option>
												<option v-for="row in provinsi" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
											</select>
										</div>
										<div class="form-group">
											<label for="">Kota / Kabupaten <span class="text-danger">*</span></label>
											<select class="custom-select form-control" @change="getKecamatan(membership.kota)" v-model="membership.kota">
												<option selected>Pilih Kota/Kabupaten</option>
												<option v-for="row in kota" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
											</select>
										</div>
										<div class="form-group">
											<label for="">Kecamatan <span class="text-danger">*</span></label>
											<select class="custom-select form-control" @change="getDesa(membership.kecamatan)" v-model="membership.kecamatan">
												<option selected>Pilih Kecamatan</option>
												<option v-for="row in kecamatan" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
											</select>
										</div>
										<div class="form-group">
											<label for="">Desa <span class="text-danger">*</span></label>
											<select class="custom-select form-control" v-model="membership.desa">
												<option selected>Pilih Desa</option>
												<option v-for="row in desa" :key="row.kode" v-bind:value="row.kode"><span class="text-capitalize">{{ row.nama }}</span></option>
											</select>
										</div>
										<div class="form-group">
											<label for="alamat">Alamat Lengkap<span class="text-danger">*</span></label>
											<textarea placeholder="Masukkan Alamat Lengkap" class="form-control" required name="alamat" v-model="membership.alamat"></textarea>
										</div>
										<div class="form-group">
											<label for="alamat_praktek">Alamat Praktek</label>
											<textarea placeholder="Masukkan Alamat Praktek" class="form-control" required name="alamat_praktek" v-model="membership.alamat_praktek"></textarea>
										</div>
										<div class="form-group">
											<label for="foto">Foto</label>
											<input class="form-control" type="file" name="foto" required="" @change="inputImage" />
										</div>
			                			<img :src="image" >
										
										<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Simpan</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
												<span class="sr-only">Loading...</span>
											</div>
										</button>
										</div>
										<!-- /.card-body -->					               
									</form>
								</div>
		            			<div class="col-2">

								</div>
		            		</div>
		            	</div>
		            </div>
		        </div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader3 from '@/components/ContentHeader3'

import { ref, reactive, onMounted } from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'
import { objectToForm } from 'object-to-form'

const loading = ref(false)

// Fetching Data
const membership = reactive({
	'nama' : null,
	'angkatan' : null,
	'npm' : null,
	'no_hp' : null,
	'email' : null,
	'tanggal_lahir' : null,
	'foto' : null,
	'provinsi' : null,
	'kota' : null,
	'kecamatan' : null,
	'desa' : null,
	'alamat' : null,
	'alamat_praktek' : null
})
const image = ref(null)

const inputImage = (e) => {
	console.log(e)
	var files = e.target.files || e.dataTransfer.files;
	membership.foto = files[0]
	createImage(files[0]);
}

const createImage = (file) => {
	var reader = new FileReader();

	reader.onload = (e) => {
		image.value = e.target.result;
		console.log(e)
	}

	if (file) reader.readAsDataURL(file) 
}

// Get Provinsi
const provinsi = ref([])

const getProvinsi = async () => {
	let response = await axios.get('api/wilayah/provinsi')

	provinsi.value = response.data
}

// End Get Provinsi

// Get Kota
const kota = ref([])

const getKota = async (kode) => {
	let response = await axios.get(`api/wilayah/kota/${kode}`)
	kota.value = response.data
}
// End Get Kota

// Get kecamatan
const kecamatan = ref([])

const getKecamatan = async (kode) => {
	let response = await axios.get(`api/wilayah/kecamatan/${kode}`)
	kecamatan.value = response.data
}
// End Get kecamatan

// Get desa
const desa = ref([])

const getDesa = async (kode) => {
	let response = await axios.get(`api/wilayah/desa/${kode}`)
	desa.value = response.data
}
// End Get desa

onMounted(() => {
	getProvinsi()
})

const prosesData = () => {
	loading.value = true
	axios.post(`api/membership/create`, objectToForm(membership))
	.then(() => {
		Swal.fire({
			title: 'Berhasil!',
			text: 'Berhasil Menambahkan Data',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500
		})
		loading.value = false
		router.push('/data-membership')
	})
	.catch(() => {
		Swal.fire({
			title: 'Gagal!',
			text: 'Gagal Menambahkan Data',
			icon: 'error',
			showConfirmButton: false,
			timer: 1500
		})	
		loading.value = false
	})
}

</script>

<style>
img {
  width: 30%;
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
}
</style>